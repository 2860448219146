import React, { FC } from "react";
import { CkModal } from "../../../../CkUI";
import './styles.css';

interface IProps {
  isOpen: boolean;
  onCancel: Function;
  onOk: Function;
}

const ExitEditionModal : FC<IProps> = ({
  isOpen,
  onCancel,
  onOk
}) => {
  return (
    <CkModal
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      zIndex={6000}
      title="¿Deseas guardar los cambios?"
      primaryAction={{
        label: "Seguir editando",
        onClick: onCancel
      }}
      secondaryAction={{
        label: "Salir sin guardar cambios",
        variant: "link",
        color: "danger",
        onClick: onOk
      }}
      actionButtonsDirection="column"
    >
      <p>Has realizado cambios en tus servicios que aún no se han guardado. ¿Quieres guardar los cambios ahora?</p>
    </CkModal>
  )
};

export default ExitEditionModal;
