import React, { useState, useEffect, memo, useCallback } from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CkButton, CkMessage } from "../../../../CkUI";
import {
  getExpandedMenu,
  getStagedWorkshopServices
} from "../../../../stores";
import WorkshopServiceForm from "../WorkshopServiceForm";
import ExitEditionModal from "../ExitEditionModal";
import "./styles.css";
import { setWorkshopServices } from "shared";

interface IProps {
  isOpen: boolean;
  setIsOpen: Function;
  contentWrapper?: "drawer" | "section";
}

const AddWorkshopServices: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  contentWrapper = "drawer",
}) => {
  const dispatch = useDispatch();
  const isMenuExpanded = useSelector(getExpandedMenu);
  const [displayConfirmationModal, setDisplayconfirmationModal] =
    useState<boolean>(false);
  const stagedServices = useSelector(getStagedWorkshopServices);

  const getStagedService = useCallback(
    () =>
      stagedServices.find(
        (stagedServices) => !!stagedServices.service.workshopServiceId === false
      ),
    [stagedServices]
  );

  const closeDrawer = () => {
    const stagedService = getStagedService();
    if (stagedService && stagedService.isDirty) {
      setDisplayconfirmationModal(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleAddService = () => {
    const stagedService = getStagedService();
    if (stagedService.isValid === false) {
      CkMessage({
        type: "error",
        text: stagedService.message,
      });
      return;
    }
    dispatch(setWorkshopServices()).then((response) => {
      const { payload } = response;
      if (payload === undefined) {
        CkMessage({
          type: "error",
          text: "Se produjo un error al crear el servicio.",
        });
      } else {
        CkMessage({
          type: "success",
          text: "Servicio creado con éxito.",
        });
        setIsOpen(false);
      }
    });
  };

  /**
   * Components
   */
  const Content = () => (
    <section className="content-wrapper">
      <div className="content-header">
        <CkButton
          onClick={closeDrawer}
          icon="caret-left"
          shape="circle"
          variant="link"
        />
        <h2>Nuevo servicio</h2>
      </div>
      <h2 className="responsive-title">Nuevo servicio</h2>
      {WorkshopServiceForm({ formType: "new" })}

      <div className="content-footer">
        <CkButton
          disabled={
            getStagedService() === undefined ||
            getStagedService()?.isDirty === false
          }
          onClick={handleAddService}
        >
          Agregar
        </CkButton>
        <CkButton variant="link" color="danger" onClick={closeDrawer}>
          Cancelar
        </CkButton>
      </div>
      <ExitEditionModal
        isOpen={displayConfirmationModal}
        setIsOpen={setDisplayconfirmationModal}
        onOk={() => setIsOpen(false)}
        onCancel={() => setDisplayconfirmationModal(false)}
      />
    </section>
  );

  if (contentWrapper === "drawer")
    return (
      <Drawer
        open={isOpen}
        forceRender={true}
        className={`add-workshop-services${
          isMenuExpanded ? " expanded-menu" : ""
        }`}
        closable={true}
        onClose={closeDrawer}
      >
        {Content()}
      </Drawer>
    );

  return <section className="add-workshop-services page">{Content()}</section>;
};

export default memo(AddWorkshopServices);
