import React, { FC, useState, memo, useCallback } from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CkButton, CkMessage } from "../../../../CkUI";
import {
  getExpandedMenu,
  getStagedWorkshopServices,
  getWorkshopServices,
} from "../../../../stores";
import AddServicesButton from "../AddServicesButton";
import ExitEditionModal from "../ExitEditionModal";
import EditServiceCard from "../EditServiceCard";
import { clearStagedServices, updateWorkshopServices } from "shared";
import "./styles.css";
interface IProps {
  isOpen: boolean;
  setIsOpen: Function;
  contentWrapper?: "drawer" | "section";
}

const EditWorkshopServices: FC<IProps> = ({
  isOpen,
  setIsOpen,
  contentWrapper = "drawer",
}) => {
  const dispatch = useDispatch();
  const isMenuExpanded = useSelector(getExpandedMenu);
  const [displayConfirmationModal, setDisplayconfirmationModal] =
    useState<boolean>(false);
  const stagedServices = useSelector(getStagedWorkshopServices);
  const workshopServices = useSelector(getWorkshopServices);

  const getStagedServices = useCallback(
    () =>
      stagedServices.filter(
        (stagedServices) => !!stagedServices.service.workshopServiceId
      ),
    [stagedServices]
  );

  const closeDrawer = () => {
    const stagedServices = getStagedServices();
    if (stagedServices && stagedServices.some((staged) => staged.isDirty)) {
      setDisplayconfirmationModal(true);
    } else {
      confimClose();
    }
  };

  const confimClose = () => {
    dispatch(clearStagedServices());
    setIsOpen(false);
  }

  const handleSaveService = () => {
    const stagedServices = getStagedServices();
    if (stagedServices.some((staged) => staged.isValid === false)) {
      const stagedWithError = stagedServices.find((staged) => staged.isValid === false);
      CkMessage({
        type: "error",
        text: `${stagedWithError.service.workshopServiceName} - ${stagedWithError.message}`,
      });
      return;
    }
    dispatch(updateWorkshopServices()).then((response) => {
      const { payload } = response;
      if (payload === undefined) {
        CkMessage({
          type: "error",
          text: "Se produjo un error al guardar los datos.",
        });
      } else {
        CkMessage({
          type: "success",
          text: "Se guardaron los datos con éxito.",
        });
        confimClose();
      }
    });
  };

  /**
   * Effects
   */

  /**
   * Components
   */
  const Content = () => (
    <section className="content-wrapper">
      <div className="content-header">
        <CkButton
          onClick={closeDrawer}
          icon="caret-left"
          shape="circle"
          variant="link"
        />
        <h2>Servicios</h2>
        <CkButton icon="export" variant="text">
          Exportar lista de servicios
        </CkButton>
      </div>
      <div className="resume">
        <p>
          <span>{workshopServices.length}</span> Servicios en este taller
        </p>
        <CkButton
          disabled={getStagedServices().every(
            (staged) => staged.isDirty === false
          )}
          onClick={handleSaveService}
          icon="save"
        >
          Guardar cambios
        </CkButton>
      </div>
      <AddServicesButton />
      <div className="edit-serive-cards">
        {workshopServices.map((service, id) => (
          <EditServiceCard
            key={id}
            service={service}
            borderBottom={id === workshopServices.length - 1}
          />
        ))}
      </div>

      <ExitEditionModal
        isOpen={displayConfirmationModal}
        onOk={() => confimClose()}
        onCancel={() => setDisplayconfirmationModal(false)}
      />
    </section>
  );

  if (contentWrapper === "drawer")
    return (
      <Drawer
        open={isOpen}
        forceRender={true}
        className={`edit-workshop-services${
          isMenuExpanded ? " expanded-menu" : ""
        }`}
        closable={true}
        onClose={closeDrawer}
      >
        {Content()}
      </Drawer>
    );

  return <section className="edit-workshop-services page">{Content()}</section>;
};

export default memo(EditWorkshopServices);
