import React, { useState } from "react";
import { CkAddButton } from "../../../../CkUI";
import AddWorkshopServices from "../AddWorkshopServices";

const AddServicesButton = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <CkAddButton onClick={() => setIsOpen(true)}>
        Agregar servicio
      </CkAddButton>
      {isOpen && <AddWorkshopServices isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default AddServicesButton;
