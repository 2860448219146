import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import {
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Tooltip,
  Checkbox,
} from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  WorkshopService,
  WorkshopServicePayload,
  WorkshopServiceType,
  translatePathToIcon,
  IPackageCategory,
  IServiceCategory,
  setStagedService,
  WorkshopServiceCase,
  PackageCodeType,
  PackageDescType,
} from "shared";
import {
  CkButton,
  CkCheckboxGroup,
  CkIcon,
  CkIconNames,
} from "../../../../CkUI";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPackagesCategories,
  getServiceCases,
  getServicesCategories,
  getStagedWorkshopServices,
  getVehicleZones,
  getWorkshopServices,
} from "../../../../stores";
import { debounce2, tierCodeToDesc } from "../../../Utilities";

const DEBOUNCE_DELAY = 200; // You can set the delay as desired

/**
 * This function parse a number to currency format
 * @param price number
 * @returns string
 */
export const parseCurrency = (price: number): string =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
  }).format(price);

interface IProps {
  formType: "new" | "edit";
  serviceId?: number;
}

const emptyService: WorkshopServicePayload = {
  workshopServiceId: 0, // No se enviará en el POST para insertar, pero si en el PUT, DELETE, GET. Se devolverá en el servicio
  workshopServiceName: "", //Nombre del servicio, no puede tener repetidos
  workshopServiceType: "", //REP = REPARACIÓN, MAN = MANTENIMIENTO
  workshopServiceStatus: "ACT", //ACT = Para mostrar en EndConsumer INA = Para Ocultar
};

const { TextArea } = Input;

const WorkshopServiceForm: FC<IProps> = ({
  formType = "new",
  serviceId = 0,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>();
  const [form] = Form.useForm();
  const packagesCategories = useSelector(getPackagesCategories);
  const servicesCaregories = useSelector(getServicesCategories);
  const vehicleZones = useSelector(getVehicleZones);
  // const serviceCases = useSelector(getServiceCases);
  const [type, setType] = useState<WorkshopServiceType | "">("");
  const [category, setCategory] = useState<
    IPackageCategory | IServiceCategory | undefined
  >(undefined);
  const [hasPlusTier, setHasPlusTier] = useState<boolean>(false);
  const [hasPremiumTier, setHasPremiumTier] = useState<boolean>(false);
  const stagedServices = useSelector(getStagedWorkshopServices);
  const workshopServices = useSelector(getWorkshopServices);
  const [serviceCases, setServiceCases] = useState<string[]>([]);
  const [newServiceCases, setNewServiceCases] = useState<WorkshopServiceCase[]>(
    []
  );
  const [availableServiceCases, setAvailableServiceCases] = useState<
    WorkshopServiceCase[]
  >([]);

  const getService = useCallback(() => {
    return workshopServices.find(
      (service) => service.workshopServiceId === serviceId
    );
  }, [workshopServices]);

  const getStagedService = useCallback(() => {
    return stagedServices.find(
      (stagedService) => stagedService.service.workshopServiceId === serviceId
    );
  }, [stagedServices]);

  /**
   * Functions
   */
  const checkChanges = async (
    ...args: any[] /* changedValues: any, values: any */
  ) => {
    const [_, values, forceDirty] = args;

    const queryStagedService = getStagedService();
    if (queryStagedService === undefined) return;

    const stagedService = { ...queryStagedService };

    stagedService.service = { ...stagedService.service };

    if (stagedService.isDirty === false) {
      const checkFormDirty = form.isFieldsTouched();
      stagedService.isDirty = checkFormDirty;
    }
    if (forceDirty) stagedService.isDirty = true;

    stagedService.isValid = true;
    stagedService.message = "";

    // Form object
    stagedService.service.workshopServiceName = values["service-name"] || "";
    stagedService.service.workshopServiceType = values["service-type"] || "";
    stagedService.service.vehicleServiceZones = values["vehicle-zones"]
      ? values["vehicle-zones"].map((zoneCode) => {
          return vehicleZones.find(
            (catalogZone) => catalogZone.vehicleZonesId === zoneCode
          );
        })
      : [];

    if (stagedService.service.workshopServiceType === "MAN") {
      stagedService.service.servicePackageCode =
        values["service-category"] || "";
      stagedService.service.servicePackageDesc =
        stagedService.service.servicePackageCode === ""
          ? ""
          : packagesCategories.find(
              (pack) =>
                pack.servicePackageCode ===
                stagedService.service.servicePackageCode
            )?.servicePackageDesc || "";

      stagedService.service.serviceTiers = [
        // basic
        {
          servicePackageTypeCode: "BASICO",
          servicePackageTypeDsc: "Básico",
          servicePackageTypeDetails: values["tier-basic-includes"] || "",
          servicePackageTypeCodeBasePrice: values["tier-basic-price"] || 0,
          servicePackageTypeComments: values["tier-basic-comments"] || "",
        },
        ...(values["tier-plus"] !== undefined && values["tier-plus"].length > 0
          ? [
              {
                servicePackageTypeCode: "PLUS" as PackageCodeType,
                servicePackageTypeDsc: tierCodeToDesc("PLUS"),
                servicePackageTypeDetails: values["tier-plus-includes"] || "",
                servicePackageTypeCodeBasePrice: values["tier-plus-price"] || 0,
                servicePackageTypeComments: values["tier-plus-comments"] || "",
              },
            ]
          : []),
        ...(values["tier-premium"] !== undefined &&
        values["tier-premium"].length > 0
          ? [
              {
                servicePackageTypeCode: "PREMIUM" as PackageCodeType,
                servicePackageTypeDsc: tierCodeToDesc("PREMIUM"),
                servicePackageTypeDetails:
                  values["tier-premium-includes"] || "",
                servicePackageTypeCodeBasePrice:
                  values["tier-premium-price"] || 0,
                servicePackageTypeComments:
                  values["tier-premium-comments"] || "",
              },
            ]
          : []),
      ];
    }

    if (stagedService.service.workshopServiceType === "REP") {
      stagedService.service.srvcTypeCode = values["service-category"] || "";
      stagedService.service.srvcTypeDsc =
        stagedService.service.srvcTypeCode === ""
          ? ""
          : servicesCaregories.find(
              (service) =>
                service.serviceTypeCode === stagedService.service.srvcTypeCode
            )?.serviceTypeName || "";
      stagedService.service.basePrice = values["service-basic-price"] || 0;
      stagedService.service.serviceComments = values["service-comments"] || "";

      const service = getService();
      if (service === undefined) {
        const defaultServiceCases: WorkshopServiceCase[] = Array.isArray(
          values["service-cases"]
        )
          ? (values["service-cases"] || [])
              .filter((serviceCase) => serviceCase !== undefined)
              .map((serviceCase) =>
                availableServiceCases.find(
                  (availableCase) =>
                    availableCase.serviceCaseDesc === serviceCase
                )
              ) || []
          : [];
        const typedServiceCases: WorkshopServiceCase[] = Array.isArray(
          values["new-case"]
        )
          ? (values["new-case"] || [])
              .filter((serviceCase) => serviceCase !== undefined)
              .map((serviceCase) => {
                return {
                  workshopCustomServicesCaseId: 0,
                  serviceCaseDesc: serviceCase,
                };
              }) || []
          : [];
        stagedService.service.serviceCases = [
          ...defaultServiceCases,
          ...typedServiceCases,
        ];
      } else {
        const defaultServiceCases: WorkshopServiceCase[] = Array.isArray(
          values["service-cases"]
        )
          ? values["service-cases"]
              .filter((serviceCase) => serviceCase !== undefined)
              .map((serviceCase) =>
                availableServiceCases.find(
                  (availableCase) =>
                    availableCase.serviceCaseDesc === serviceCase
                )
              ) || []
          : [];
        const typedServiceCases: WorkshopServiceCase[] = Array.isArray(
          values["new-case"]
        )
          ? values["new-case"]
              .filter((serviceCase) => serviceCase !== undefined)
              .map((serviceCase, index) => {
                return {
                  workshopCustomServicesCaseId:
                    newServiceCases[index].workshopCustomServicesCaseId,
                  serviceCaseDesc: serviceCase,
                };
              }) || []
          : [];
        stagedService.service.serviceCases = [
          ...defaultServiceCases,
          ...typedServiceCases,
        ];
      }
    }

    // Validate name
    if (
      stagedService.service.workshopServiceName.length < 3 ||
      stagedService.service.workshopServiceName.length > 35
    ) {
      stagedService.isValid = false;
      stagedService.message =
        "El nombre del servicio no acepta caracteres numéricos y tiene un límite de 35 caracteres.";
    }

    // Validate type
    if (
      stagedService.service.workshopServiceType !== "MAN" &&
      stagedService.service.workshopServiceType !== "REP"
    ) {
      stagedService.isValid = false;
      stagedService.message = "Debe indicar el tipo de servicio.";
    }

    if (stagedService.service.workshopServiceType === "MAN") {
      // Validate category
      if (
        stagedService.service.servicePackageCode === "" ||
        stagedService.service.servicePackageDesc === ""
      ) {
        stagedService.isValid = false;
        stagedService.message = "Debe indicar la categoría del servicio.";
      }

      stagedService.service.serviceTiers.forEach((tier) => {
        if (tier.servicePackageTypeDetails === "") {
          stagedService.isValid = false;
          stagedService.message = `Debe indicar que incluye el paquete ${tier.servicePackageTypeDsc.toLocaleLowerCase()}.`;
        }
        if (tier.servicePackageTypeCodeBasePrice === 0) {
          stagedService.isValid = false;
          stagedService.message = `Debe indicar el precio base del paquete ${tier.servicePackageTypeDsc.toLocaleLowerCase()}.`;
        }
      });

      if (
        stagedService.service.servicePackageCode === "O01" &&
        stagedService.service.vehicleServiceZones.length === 0
      ) {
        stagedService.isValid = false;
        stagedService.message =
          "Debe indicar en que partes del vehículo aplica la reparación.";
      }
    }
    if (stagedService.service.workshopServiceType === "REP") {
      // Validate category
      if (
        stagedService.service.srvcTypeCode === "" ||
        stagedService.service.srvcTypeDsc === ""
      ) {
        stagedService.isValid = false;
        stagedService.message = "Debe indicar la categoría del servicio.";
      }
      if (stagedService.service.serviceCases.length === 0) {
        stagedService.isValid = false;
        stagedService.message = "Debe en que casos aplica el servicio.";
      }
      if (
        stagedService.service.srvcTypeCode === "Other" &&
        stagedService.service.vehicleServiceZones.length === 0
      ) {
        stagedService.isValid = false;
        stagedService.message =
          "Debe indicar en que partes del vehículo aplica la reparación.";
      }
    }

    dispatch(setStagedService(stagedService));
  };

  const debouncedCheckChanges = debounce2(checkChanges, DEBOUNCE_DELAY);

  const onTypeChange = (e: RadioChangeEvent) => {
    form.setFieldValue("service-category", "");
    setType(e.target.value);
    setCategory(undefined);
  };

  const onCategoryChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    let objectValue: IServiceCategory | IPackageCategory;
    if (type === "MAN") {
      objectValue = packagesCategories.find(
        (pack) => pack.servicePackageCode === value
      );
      setServiceCases([]);
    } else {
      objectValue = servicesCaregories.find(
        (service) => service.serviceTypeCode === value
      );
      const _serviceCases = objectValue.relatedFailures.map(
        (failure) => failure.srvcTypeFailRelatedDesc
      )
      setServiceCases(
        _serviceCases
      );
    }
    setCategory(objectValue);
  };

  const onPlusTierChange = (checkedValue: CheckboxValueType[]) => {
    setHasPlusTier(checkedValue.length > 0);
  };

  const onPremiumTierChange = (checkedValue: CheckboxValueType[]) => {
    setHasPremiumTier(checkedValue.length > 0);
  };

  /**
   * Effects
   */
  useEffect(() => {
    if (formType === "edit" && serviceId) {
      const staged = getStagedService();
      const service = staged ? staged.service : getService();
      // set states
      setType(service.workshopServiceType);
      const _category =
        service.workshopServiceType === "MAN"
          ? packagesCategories.find(
              (category) =>
                category.servicePackageCode === service.servicePackageCode
            )
          : servicesCaregories.find(
              (category) => category.serviceTypeCode === service.srvcTypeCode
            );
      const _serviceCases =
        service.workshopServiceType === "MAN"
          ? []
          : (_category as IServiceCategory).relatedFailures.map(
              (relatedFailure) => relatedFailure.srvcTypeFailRelatedDesc
            );
      setServiceCases(_serviceCases);
      setCategory(_category);
      // set form values

      form.setFieldsValue({
        "service-name": service.workshopServiceName,
        "service-type": service.workshopServiceType,
        "service-category":
          service.workshopServiceType === "MAN"
            ? service.servicePackageCode
            : service.srvcTypeCode,
        "vehicle-zones": service.vehicleServiceZones.map(
          (zone) => zone.vehicleZonesId
        ),
      });

      if (service.workshopServiceType === "MAN") {
        // basic tier
        const basicTier = service.serviceTiers.find(
          (tier) => tier.servicePackageTypeCode === "BASICO"
        );
        if (basicTier) {
          form.setFieldsValue({
            "tier-basic-comments": basicTier.servicePackageTypeComments,
            "tier-basic-includes": basicTier.servicePackageTypeDetails,
            "tier-basic-price": basicTier.servicePackageTypeCodeBasePrice,
          });
        }
        // plus tier
        const plusTier = service.serviceTiers.find(
          (tier) => tier.servicePackageTypeCode === "PLUS"
        );
        if (plusTier) {
          setHasPlusTier(true);
          form.setFieldsValue({
            "tier-plus": [true],
            "tier-plus-comments": plusTier.servicePackageTypeComments,
            "tier-plus-includes": plusTier.servicePackageTypeDetails,
            "tier-plus-price": plusTier.servicePackageTypeCodeBasePrice,
          });
        }

        // premium tier
        const premiumTier = service.serviceTiers.find(
          (tier) => tier.servicePackageTypeCode === "PREMIUM"
        );
        if (premiumTier) {
          setHasPremiumTier(true);
          form.setFieldsValue({
            "tier-premium": [true],
            "tier-premium-comments": premiumTier.servicePackageTypeComments,
            "tier-premium-includes": premiumTier.servicePackageTypeDetails,
            "tier-premium-price": premiumTier.servicePackageTypeCodeBasePrice,
          });
        }
      } else {
        const extraCases = service.serviceCases.filter(
          (currentCase) =>
            _serviceCases.includes(currentCase.serviceCaseDesc) === false
        );
        const regularCases = service.serviceCases.filter(
          (currentCase) =>
            _serviceCases.includes(currentCase.serviceCaseDesc) === true
        );
        form.setFieldsValue({
          "service-basic-price": service.basePrice,
          "service-cases": regularCases.map(
            (serviceCase) => serviceCase.serviceCaseDesc
          ),
          "service-comments": service.serviceComments,
        });
        if (extraCases && extraCases.length > 0) {
          form.setFieldValue(
            "new-case",
            extraCases.map((serviceCase) => serviceCase.serviceCaseDesc)
          );
          setNewServiceCases(extraCases);
        }
      }
      dispatch(
        setStagedService({
          ...(staged
            ? {
                isDirty: staged.isDirty,
                isValid: staged.isValid,
                message: staged.message,
              }
            : {
                isDirty: false,
                isValid: true,
                message: "",
              }),
          service: service,
        })
      );
    }
    if (formType === "new") {
      dispatch(
        setStagedService({
          isDirty: false,
          isValid: false,
          message: "",
          service: emptyService,
        })
      );
    }
  }, [formType, serviceId, workshopServices]);

  useEffect(() => {
    if (type === "REP" && formType === "new") {
      form.setFieldValue("service-cases", serviceCases);
    }
  }, [type]);

  useEffect(() => {
    if (serviceCases.length > 0) {
      const service = getService();
      if (service) {
        const regularCases = service.serviceCases.filter(
          (currentCase) =>
            serviceCases.includes(currentCase.serviceCaseDesc) === true
        );
        setAvailableServiceCases(
          serviceCases.map((serviceCase) => {
            {
              const findCase = regularCases.find(
                (regularCase) => regularCase.serviceCaseDesc === serviceCase
              );
              return {
                serviceCaseDesc: serviceCase,
                workshopCustomServicesCaseId: findCase
                  ? findCase.workshopCustomServicesCaseId
                  : 0,
              };
            }
          })
        );
      } else {
        setAvailableServiceCases(
          serviceCases.map((serviceCase) => {
            {
              return {
                serviceCaseDesc: serviceCase,
                workshopCustomServicesCaseId: 0,
              };
            }
          })
        );
        form.setFieldValue("service-cases", serviceCases);
      }
    } else {
      setAvailableServiceCases([]);
      form.setFieldValue("service-cases", []);
    }
  }, [serviceCases, serviceId]);

  /**
   * Components
   */
  const CategoryServiceCard: FC<{
    type: "package" | "service";
    icon: string;
    label: string;
  }> = ({ type, icon, label }) => (
    <div className="category-service-card">
      <CkIcon name={translatePathToIcon(icon, type, () => {}) as CkIconNames} />
      <p>{label}</p>
    </div>
  );

  if (
    (formType === "edit" && serviceId === undefined) ||
    getStagedService() === undefined
  )
    return null;

  return (
    <Form form={form} onValuesChange={debouncedCheckChanges}>
      <section
        className={`workshop-service-form ${formType}-service`}
        ref={componentRef}
      >
        <div className="form-section service-name">
          <h3>
            <span>1</span>Nombre del servicio<sup>*</sup>
          </h3>
          <div className="info-container">
            <CkIcon name="information-round" />
            <p>
              Al nombrar el servicio, asegúrate de que sea claro y descriptivo,
              para que tus clientes comprendan lo que ofreces.
            </p>
          </div>
          <Form.Item
            name="service-name"
            help="Este campo no acepta caracteres numéricos y tiene un límite de 35 caracteres."
            rules={[
              {
                pattern: /^[^\d]{3,35}$/,
                message:
                  "Este campo no acepta caracteres numéricos y tiene un límite de 35 caracteres.",
              },
            ]}
            hasFeedback
            required
          >
            <Input
              placeholder="Ejemplo: Servicio de mantenimiento"
              minLength={3}
              maxLength={35}
            />
          </Form.Item>
        </div>
        <div className="form-section service-type">
          <h3>
            <span>2</span>Tipo de servicio<sup>*</sup>
          </h3>
          <Form.Item name="service-type">
            <Radio.Group onChange={onTypeChange} value={type}>
              <Radio value={"MAN"}>Mantenimiento</Radio>
              <Radio value={"REP"}>Reparación</Radio>
            </Radio.Group>
          </Form.Item>
          <div className="info-container">
            <CkIcon name="information-round" />
            <div>
              <p>
                <b>Mantenimiento:</b> Consisten en servicios estandarizados, con
                un precio definido, para el cuidado óptimo de un vehículo.
              </p>
              <p>
                <b>Reparación:</b> Son las correcciones de una falla o problema
                específico en el vehículo.
              </p>
            </div>
          </div>
        </div>
        <div className="form-section service-category">
          <h3>
            <span>3</span>Categoría del servicio<sup>*</sup>
          </h3>
          <div className="info-container">
            <CkIcon name="information-round" />
            <p>¿A qué categoría pertenece este servicio?</p>
          </div>
          {category !== undefined && (
            <div className="selector-service-category">
              <CategoryServiceCard
                type={type === "MAN" ? "package" : "service"}
                label={
                  type === "MAN"
                    ? (category as IPackageCategory).servicePackageDesc
                    : (category as IServiceCategory).serviceTypeName
                }
                icon={category.iconPath}
              />
              <CkButton
                variant="link"
                block={false}
                onClick={() => {
                  setCategory(undefined);
                  form.setFieldValue("service-category", undefined);
                }}
              >
                Cambiar
              </CkButton>
            </div>
          )}

          <Form.Item
            name="service-category"
            className={`service-category${
              category === undefined ? "" : " hidden"
            }`}
          >
            <Radio.Group onChange={onCategoryChange} value={category}>
              {type === "MAN" &&
                packagesCategories.map((pack) => (
                  <Radio
                    key={pack.servicePackageCode}
                    value={pack.servicePackageCode}
                  >
                    <CategoryServiceCard
                      type="package"
                      label={pack.servicePackageDesc}
                      icon={pack.iconPath}
                    />
                  </Radio>
                ))}
              {type === "REP" &&
                servicesCaregories.map((service) => (
                  <Radio
                    key={service.serviceTypeCode}
                    value={service.serviceTypeCode}
                  >
                    <CategoryServiceCard
                      type="service"
                      label={service.serviceTypeName}
                      icon={service.iconPath}
                    />
                  </Radio>
                ))}
            </Radio.Group>
          </Form.Item>
          {((category?.serviceTypeCode &&
            category?.serviceTypeCode === "Other") ||
            (category?.servicePackageCode &&
              category?.servicePackageCode === "O01")) && (
            <div className="vehicle-zones">
              <p>
                <b>¿Para qué parte del vehículo es este servicio?</b> Puedes
                elegir más de uno si aplica.
              </p>
              <Form.Item
                name="vehicle-zones"
                rules={[
                  {
                    required: true,
                    type: "array",
                    message:
                      "Eliga al menos una parte del vehículo donde se aplica el servicio.",
                  },
                ]}
              >
                <Checkbox.Group
                  options={vehicleZones.map((zone) => {
                    return {
                      label: zone.vehicleZonesDesc,
                      value: zone.vehicleZonesId,
                    };
                  })}
                />
              </Form.Item>
            </div>
          )}
        </div>
        {type === "MAN" && category !== undefined && (
          <div className="form-section service-man">
            <h3>
              <span>4</span>Detalles de paquetes
            </h3>
            <div className="tier-container">
              <div className="tier-title-container">
                <p>
                  Paquete básico <span>$</span>
                </p>
              </div>
              <div className="tier-fields-container">
                <div className="price-container">
                  <p className="tier-field-label">
                    Precio base<sup>*</sup>
                  </p>
                  <Form.Item
                    name="tier-basic-price"
                    rules={[
                      {
                        required: true,
                        message:
                          "Debe indicar el precio base del paquete básico",
                      },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      formatter={parseCurrency}
                      placeholder="$0.00"
                    />
                  </Form.Item>
                  <p>Incluye IVA</p>
                </div>
                <div className="what-includes-container">
                  <p className="tier-field-label">
                    ¿Qué incluye?<sup>*</sup>
                  </p>
                  <Form.Item
                    name="tier-basic-includes"
                    rules={[
                      {
                        required: true,
                        message:
                          "Debe indicar el que incluye el paquete básico",
                      },
                    ]}
                  >
                    <TextArea />
                  </Form.Item>
                </div>
                <div className="comments-container">
                  <p className="tier-field-label">
                    Comentarios y condiciones <span>Opcional</span>
                  </p>
                  <Form.Item name="tier-basic-comments">
                    <TextArea />
                  </Form.Item>
                </div>
              </div>
            </div>
            {formType === "new" && (
              <p className="cta">Agregar paquetes adicionales:</p>
            )}
            <div className="tier-container optional">
              <div className="tier-title-container">
                <CkCheckboxGroup
                  name="tier-plus"
                  checkboxProps={{
                    onChange: onPlusTierChange,
                  }}
                  options={[
                    {
                      label: "",
                      value: true,
                    },
                  ]}
                />
                <p>
                  Paquete plus <span>$$</span>
                </p>
                <Tooltip
                  title="Si cuentas con un paquete que ofrezca más beneficios y tenga un precio mayor al Paquete básico, agrégalo aquí."
                  getPopupContainer={(e) => e || document.body}
                  overlayClassName="tier-tooltip"
                >
                  <CkIcon name="information-round" />
                </Tooltip>
              </div>
              {hasPlusTier && (
                <div className="tier-fields-container">
                  <div className="price-container">
                    <p className="tier-field-label">
                      Precio base<sup>*</sup>
                    </p>
                    <Form.Item
                      name="tier-plus-price"
                      rules={[
                        {
                          required: hasPlusTier === true,
                          message:
                            "Debe indicar el precio base del paquete plus",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={parseCurrency}
                        placeholder="$0.00"
                      />
                    </Form.Item>
                    <p>Incluye IVA</p>
                  </div>
                  <div className="what-includes-container">
                    <p className="tier-field-label">
                      ¿Qué incluye?<sup>*</sup>
                    </p>
                    <Form.Item
                      name="tier-plus-includes"
                      rules={[
                        {
                          required: hasPlusTier === true,
                          message:
                            "Debe indicar el que incluye el paquete plus",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </div>
                  <div className="comments-container">
                    <p className="tier-field-label">
                      Comentarios y condiciones <span>Opcional</span>
                    </p>
                    <Form.Item name="tier-plus-comments">
                      <TextArea />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
            <div className="tier-container optional">
              <div className="tier-title-container">
                <CkCheckboxGroup
                  name="tier-premium"
                  checkboxProps={{
                    onChange: onPremiumTierChange,
                  }}
                  options={[
                    {
                      label: "",
                      value: true,
                    },
                  ]}
                />
                <p>
                  Paquete premium <span>$$$</span>
                </p>
                <Tooltip
                  title="Si cuentas con un paquete que ofrezca más beneficios y tenga un precio mayor al Paquete plus, agrégalo aquí."
                  overlayClassName="tier-tooltip"
                  getPopupContainer={(e) => e || document.body}
                >
                  <CkIcon name="information-round" />
                </Tooltip>
              </div>
              {hasPremiumTier && (
                <div className="tier-fields-container">
                  <div className="price-container">
                    <p className="tier-field-label">
                      Precio base<sup>*</sup>
                    </p>
                    <Form.Item
                      name="tier-premium-price"
                      rules={[
                        {
                          required: hasPremiumTier === true,
                          message:
                            "Debe indicar el precio base del paquete premium",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={parseCurrency}
                        placeholder="$0.00"
                      />
                    </Form.Item>
                    <p>Incluye IVA</p>
                  </div>
                  <div className="what-includes-container">
                    <p className="tier-field-label">
                      ¿Qué incluye?<sup>*</sup>
                    </p>
                    <Form.Item
                      name="tier-premium-includes"
                      rules={[
                        {
                          required: hasPremiumTier === true,
                          message:
                            "Debe indicar el que incluye el paquete premium",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </div>
                  <div className="comments-container">
                    <p className="tier-field-label">
                      Comentarios y condiciones <span>Opcional</span>
                    </p>
                    <Form.Item name="tier-premium-comments">
                      <TextArea />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {type === "REP" && category !== undefined && (
          <div className="form-section service-rep">
            <h3>
              <span>4</span>Detalles de servicios
            </h3>
            <div className="tier-container">
              <div className="tier-fields-container">
                <div className="price-container">
                  <p className="tier-field-label">
                    Precio base <span>Opcional</span>
                  </p>
                  <Form.Item name="service-basic-price">
                    <InputNumber
                      controls={false}
                      formatter={parseCurrency}
                      placeholder="$0.00"
                    />
                  </Form.Item>
                  <p>Incluye IVA</p>
                  <p className="info">
                    En caso de contar con un precio base, por ejemplo un precio
                    fijo de diagnóstico, agrégalo aquí.
                  </p>
                </div>
                <div className="use-cases-container">
                  <p className="tier-field-label">
                    Casos en los que se realiza este servicio<sup>*</sup>
                  </p>
                  {availableServiceCases &&
                    availableServiceCases.length > 0 && (
                      <CkCheckboxGroup
                        name="service-cases"
                        rules={[
                          {
                            required:
                              newServiceCases.length === 0 ? true : false,
                            message:
                              "Debe indicar en que casos se realiza este servicio",
                          },
                        ]}
                        options={availableServiceCases.map((serviceCase) => {
                          return {
                            label: serviceCase.serviceCaseDesc,
                            value: serviceCase.serviceCaseDesc,
                          };
                        })}
                        checkboxProps={{
                          defaultValue: serviceCases,
                        }}
                      />
                    )}
                  <div className="add-cases-container">
                    {newServiceCases.map((_, index) => (
                      <div key={index}>
                        <Form.Item>
                          <CkButton
                            className="remove-button"
                            icon="close"
                            shape="circle"
                            onClick={() => {
                              // Delete form values
                              const values = form.getFieldsValue();
                              if (values["new-case"]) {
                                form.setFieldValue(
                                  "new-case",
                                  values["new-case"].filter(
                                    (_, id) => id !== index
                                  )
                                );
                              }
                              // Delete state
                              setNewServiceCases((prevState) =>
                                prevState.filter((state, id) => id !== index)
                              );
                              checkChanges([], form.getFieldsValue(), true);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name={["new-case", index]}
                          rules={[
                            {
                              required: true,
                              pattern: /^[^\d]{3,35}$/,
                              message:
                                "Este campo no acepta caracteres numéricos y tiene un límite de 35 caracteres.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Escribe aquí"
                            minLength={3}
                            maxLength={35}
                          />
                        </Form.Item>
                      </div>
                    ))}
                    <div className="">
                      <CkButton
                        icon="plus-add"
                        shape="circle"
                        onClick={() => {
                          setNewServiceCases((prevState) => [
                            ...prevState,
                            {
                              serviceCaseDesc: "",
                              workshopCustomServicesCaseId: 0,
                            },
                          ]);
                          checkChanges([], form.getFieldsValue(), true);
                        }}
                      />
                      <p>Agregar otro caso</p>
                    </div>
                  </div>
                </div>
                <div className="comments-container">
                  <p className="tier-field-label">
                    Comentarios y condiciones <span>Opcional</span>
                  </p>
                  <Form.Item name="service-comments">
                    <TextArea />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </Form>
  );
};
export default WorkshopServiceForm;
