import React, { FC, useState, useEffect, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CkButton, CkIcon, CkIconNames, CkMessage } from "../../../../CkUI";
import {
  getPackagesCategories,
  getServicesCategories,
  getWorkshopServices,
} from "../../../../stores";
import WorkshopServiceForm, { parseCurrency } from "../WorkshopServiceForm";
import "./styles.css";
import {
  WorkshopService,
  translatePathToIcon,
  deleteWorkshopServices,
} from "shared";
import ConfirmDeletionModal from "../ConfirmDeletionModal";
import { tierCodeToDesc } from "../../../Utilities";

interface IProps {
  service: WorkshopService;
  borderBottom: boolean;
}

const EditServiceCard: FC<IProps> = ({ service, borderBottom }) => {
  const dispatch = useDispatch();
  const packagesCategories = useSelector(getPackagesCategories);
  const servicesCaregories = useSelector(getServicesCategories);
  const workshopServices = useSelector(getWorkshopServices);
  const [expanded, setExpanded] = useState<boolean>();
  const [workshopService, setworkshopService] = useState<WorkshopService>();
  const [showConfirm, setShowConfirm] = useState<boolean>();

  const getWorkshopService = useCallback(
    () =>
      workshopServices.find(
        (workshopServices) =>
          workshopServices.workshopServiceId === service.workshopServiceId
      ),
    [workshopServices]
  );

  const getIconPath = useCallback(
    (workshopService: WorkshopService) => {
      if (
        workshopService.workshopServiceType === "MAN" &&
        packagesCategories.length > 0
      ) {
        const packageCategory = packagesCategories.find(
          (category) =>
            category.servicePackageCode === workshopService.servicePackageCode
        );
        if (packageCategory === undefined) return "";
        return translatePathToIcon(
          packageCategory?.iconPath || "checkmark",
          "package",
          () => {}
        );
      }
      if (
        workshopService.workshopServiceType === "REP" &&
        servicesCaregories.length > 0
      ) {
        const serviceCategory = servicesCaregories.find(
          (category) =>
            category.serviceTypeCode === workshopService.srvcTypeCode
        );
        if (serviceCategory === undefined) return "";
        return translatePathToIcon(
          serviceCategory?.iconPath || "checkmark",
          "service",
          () => {}
        );
      }
      return "";
    },
    [packagesCategories, servicesCaregories]
  );

  const requestDeleteService = () => {
    setShowConfirm(true);
  };

  const confimClose = () => {
    setShowConfirm(false);
    dispatch(deleteWorkshopServices(service)).then((response) => {
      const { payload } = response;
      if (payload === undefined) {
        CkMessage({
          type: "error",
          text: "Se produjo un error al eliminar el servicio.",
        });
      } else {
        CkMessage({
          type: "success",
          text: "Se eliminó el servicio con éxito.",
        });
        confimClose();
      }
    });
  };

  useEffect(() => {
    setworkshopService(getWorkshopService());
  }, [workshopServices]);

  if (service === undefined) return null;

  return (
    <section
      className={[
        "edit-service-card",
        ...(borderBottom ? ["border-bottom"] : []),
        ...(expanded ? ["expanded"] : []),
      ].join(" ")}
    >
      <div className="card-header">
        {workshopService && (
          <>
            <div className="service-name">
              <CkIcon
                name={
                  (getIconPath(workshopService) as CkIconNames) || "checkmark"
                }
              />
              <p>{workshopService && workshopService.workshopServiceName}</p>
            </div>
            <div className="service-price">
              {workshopService.workshopServiceType === "MAN" &&
                workshopService.serviceTiers.map((tier) => (
                  <div className="tier-container">
                    <p className="tier-desc">{tierCodeToDesc(tier.servicePackageTypeCode)}</p>
                    <p className="tier-price">
                      {parseCurrency(tier.servicePackageTypeCodeBasePrice)}
                    </p>
                  </div>
                ))}
              {workshopService.workshopServiceType === "REP" &&
                !!workshopService.basePrice && (
                  <div className="tier-container">
                    <p className="tier-desc">Precio base</p>
                    <p className="tier-price">
                      {parseCurrency(workshopService.basePrice)}
                    </p>
                  </div>
                )}
              {workshopService.workshopServiceType === "REP" &&
                !!workshopService.basePrice === false && (
                  <p className="no-price">
                    Este servicio no tiene un precio base de diagnóstico
                    configurado
                  </p>
                )}
              <CkButton
                icon={"caret-down"}
                type={"text"}
                onClick={() => setExpanded((prevState) => !prevState)}
                block={false}
              >
                Ver detalle
              </CkButton>
            </div>
          </>
        )}
      </div>
      <div className={"card-body"}>
        {expanded && (
          <>
            <WorkshopServiceForm
              formType="edit"
              serviceId={service.workshopServiceId}
            />
            <CkButton
              onClick={requestDeleteService}
              className="delete-service-button"
              color="danger"
              type="text"
              icon="trash"
            >
              Eliminar servicio
            </CkButton>
          </>
        )}
      </div>

      <ConfirmDeletionModal
        isOpen={showConfirm}
        onOk={() => confimClose()}
        onCancel={() => setShowConfirm(false)}
      />
    </section>
  );
};

export default memo(EditServiceCard);
