import React, { FC } from "react";
import { CkModal } from "../../../../CkUI";
import './styles.css';

interface IProps {
  isOpen: boolean;
  onCancel: Function;
  onOk: Function;
}

const ConfirmDeletionModal : FC<IProps> = ({
  isOpen,
  onCancel,
  onOk
}) => {
  return (
    <CkModal
      type="delete"
      open={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      zIndex={6000}
      title="¿Deseas guardar los cambios?"
      primaryAction={{
        label: "Eliminar",
        onClick: onOk
      }}
      secondaryAction={{
        label: "Cancelar",
        variant: "link",
        onClick: onCancel
      }}
      actionButtonsDirection="column"
      className="confirm-deletion-modal"
    >
      <p>Estás a punto de eliminar un servicio de la oferta del taller.<br />
      Ten en cuenta que esta acción no se puede deshacer.</p>

      <p>¿Deseas proceder con la eliminación?</p>
    </CkModal>
  )
};

export default ConfirmDeletionModal;
