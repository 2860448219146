import React, { useState, useEffect } from "react";
import { Drawer, Button, Form, Checkbox } from "antd";
import { WorkshopService } from "shared";
import { FormInstance } from "antd/lib/form";

import "./styles.css";
import { ServicesAPI } from "../../Services/axios";
import { CkIcon } from "../../../CkUI";
import { useSelector } from "react-redux";
import { getExpandedMenu } from "../../../stores";
import { WarrantyPreview } from "../Workshop/WorkshopWarranty/WarrantyPreview";

interface IProps {
  isOpen: boolean;
  setIsOpen: Function;
  form: FormInstance;
  saveData: Function;
  workshopId?: string;
  contentWrapper?: "modal" | "drawer" | "section";
  prevStep?: Function;
  prevStepText?: string;
  nextStep?: Function;
  nextStepText?: string;
  savingData?: boolean;
  workshop?: any;
  warrantySeeMore?: Function;
}

const WorkshopServices: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  form,
  saveData,
  workshopId,
  contentWrapper = "drawer",
  prevStep,
  prevStepText,
  nextStep,
  nextStepText,
  savingData,
  workshop,
  warrantySeeMore,
}) => {
  const isMenuExpanded = useSelector(getExpandedMenu);
  const [isNewWorkshop, setIsNewWorkshop] = useState<boolean>(true);
  const [services, setServices] = useState<WorkshopService[]>([]);
  const [originalList, setOriginalList] = useState<WorkshopService[]>([]);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (
      values.carTypes &&
      values.carTypes.length > 0 &&
      values.email &&
      values.email.length > 0 &&
      values.workshopPhoneNumber &&
      values.workshopPhoneNumber.length > 0 &&
      values.customerPhoneNumber &&
      values.customerPhoneNumber.length > 0 &&
      values.location &&
      values.location.address.length > 0
    ) {
      setIsNewWorkshop(false);
    } else {
      setIsNewWorkshop(true);
    }
    if (values?.serviceTypes) {
      setOriginalList(values.serviceTypes || []);
      setCheckedValues(values.serviceTypes || []);
    }
  }, [form]);

  useEffect(() => {
    if (workshop) {
      setOriginalList(workshop?.serviceTypes || []);
      setCheckedValues(workshop?.serviceTypes || []);
    }
  }, [workshop]);

  useEffect(() => {
    const getServices = async () => {
      const result = await ServicesAPI.getAllServices();
      setServices(result.data);
    };
    getServices();
  }, []);

  const formatAndSetServices = () => {
    const newValues = services
      .filter((service) => checkedValues.includes(service.serviceTypeCode))
      .map((service) => service.serviceTypeCode);

    form.setFieldsValue({
      serviceTypes: [...newValues],
    });
  };

  const actions = (
    <div className="actions-container">
      <div
        onClick={() => {
          setIsOpen(false);
          setCheckedValues(originalList);
          prevStep && prevStep();
        }}
        className="cancel-style"
      >
        {prevStepText ? prevStepText : "Cancelar"}
      </div>
      {isNewWorkshop && (
        <Button
          onClick={() => {
            formatAndSetServices();
            setIsOpen(false);
            nextStep && nextStep(checkedValues);
          }}
          className="rhomboid-blue button-style"
          {...(savingData !== undefined ? { loading: savingData } : {})}
        >
          <div style={{ fontSize: "16px" }}>
            {nextStepText ? nextStepText : "Guardar datos"}
          </div>
        </Button>
      )}
      {!isNewWorkshop && (
        <Button
          onClick={() => {
            formatAndSetServices();
            setIsOpen(false);
            saveData();
            nextStep && nextStep(checkedValues);
          }}
          className="rhomboid-blue button-style"
          {...(savingData !== undefined ? { loading: savingData } : {})}
        >
          <div style={{ fontSize: "16px" }}>
            {nextStepText ? nextStepText : "Guardar datos"}
          </div>
        </Button>
      )}
    </div>
  );

  const Header = () => (
    <>
      <div className="title">Servicios de reparación disponibles</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CkIcon name="information-round" />
        <p className="bold" style={{ paddingLeft: "10px", fontSize: "14px" }}>
          Las reparaciones son las correcciones de una falla o problema
          específico en el vehículo.
        </p>
      </div>
      <p style={{ fontSize: "14px" }}>
        Selecciona todos los servicios que ofreces en tu taller.
      </p>
    </>
  );

  const getWarrantyInfo = (serviceTypeCode: string) => {
    const warrantyInfo = form.getFieldsValue().warranty.servicesInfo;

    if (!warrantyInfo) {
      return null;
    }

    const warrantyInfoFiltered = warrantyInfo?.filter(
      (warranty: any) => warranty.serviceTypeCode === serviceTypeCode
    );

    if (!warrantyInfoFiltered) {
      return null;
    }

    return warrantyInfoFiltered[0] || null;
  };

  if (contentWrapper === "drawer" || contentWrapper === "modal")
    return (
      <Drawer
        open={isOpen}
        forceRender={true}
        className={
          isMenuExpanded
            ? "services-container expanded-menu"
            : "services-container"
        }
        closable={true}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <Header />
        <Form.Item name="serviceTypes">
          <div className="services-list">
            {services.map((service) => {
              const warrantyInfo = getWarrantyInfo(service.serviceTypeCode);
              return (
                <>
                  <Checkbox
                    key={service.serviceTypeCode}
                    value={service.serviceTypeCode}
                    className="--alter --orange"
                    checked={checkedValues.includes(service.serviceTypeCode)}
                    onChange={(e: any) => {
                      const newValues = e.target.checked
                        ? [...checkedValues, service.serviceTypeCode]
                        : checkedValues.filter(
                            (code) => code !== service.serviceTypeCode
                          );
                      setCheckedValues(newValues);
                    }}
                  >
                    {service.serviceTypeName}
                  </Checkbox>
                  {warrantyInfo && (
                    <WarrantyPreview
                      manualLabor={warrantyInfo.manualLabor}
                      parts={warrantyInfo.parts}
                      onSeeMore={warrantySeeMore}
                    />
                  )}
                </>
              );
            })}
          </div>
        </Form.Item>
        {actions}
      </Drawer>
    );

  return (
    <section className="services-container">
      <Header />
      <Form.Item name="serviceTypes">
        <div className="services-list">
          {services.map((service) => {
            const warrantyInfo = getWarrantyInfo(service.serviceTypeCode);
            return (
              <>
                <Checkbox
                  key={service.serviceTypeCode}
                  value={service.serviceTypeCode}
                  className="--alter --orange"
                  checked={checkedValues.includes(service.serviceTypeCode)}
                  onChange={(e: any) => {
                    const newValues = e.target.checked
                      ? [...checkedValues, service.serviceTypeCode]
                      : checkedValues.filter(
                          (code) => code !== service.serviceTypeCode
                        );
                    setCheckedValues(newValues);
                  }}
                >
                  {service.serviceTypeName}
                </Checkbox>
                {warrantyInfo && (
                  <WarrantyPreview
                    manualLabor={warrantyInfo.manualLabor}
                    parts={warrantyInfo.parts}
                    onSeeMore={warrantySeeMore}
                  />
                )}
              </>
            );
          })}
        </div>
      </Form.Item>
      {actions}
    </section>
  );
};

export default React.memo(WorkshopServices);
