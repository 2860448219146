import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CkButton, CkIcon, CkIconNames } from "../../../../CkUI";
import {
  getPackagesCategories,
  getServicesCategories,
  getWorkshopServices,
} from "../../../../stores";
import { WorkshopService, translatePathToIcon } from "shared";
import EditWorkshopServices from "../EditWorkshopServices";
import "./styles.css";

const ServicesResume = () => {
  const workshopServices = useSelector(getWorkshopServices);
  const packagesCategories = useSelector(getPackagesCategories);
  const servicesCaregories = useSelector(getServicesCategories);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  
  const getIconPath = useCallback((service: WorkshopService) => {
    if (service.workshopServiceType === "MAN") {
      const packageCategory = packagesCategories.find((category) => category.servicePackageCode === service.servicePackageCode);
      return translatePathToIcon(packageCategory?.iconPath || "checkmark", "package", () => {})
    }
    if (service.workshopServiceType === "REP") {
      const serviceCategory = servicesCaregories.find((category) => category.serviceTypeCode === service.srvcTypeCode);
      return translatePathToIcon(serviceCategory?.iconPath || "checkmark", "service", () => {})
    }
    return "";
  }, [packagesCategories, servicesCaregories]);

  if (!!workshopServices ===false || workshopServices.length === 0) return null;
  
  return (
    <section className="services-resume">
      <div className="header">
        <p><span>{workshopServices.length}</span> Servicios en este taller</p>
        <CkButton
          icon="pencil"
          type="text"
          onClick={() => setOpenEdit(true)}
          block={false}
        >
          Editar servicios
        </CkButton>
      </div>
      <div className="body">
        {workshopServices.map((service) => (
          <div className="service-chip">
            <CkIcon name={getIconPath(service) as CkIconNames || "checkmark"} />
            <p>{service.workshopServiceName}</p>
          </div>
        ))}
      </div>
      {openEdit && <EditWorkshopServices isOpen={openEdit} setIsOpen={setOpenEdit} />}
    </section>
  );
};

export default ServicesResume;
